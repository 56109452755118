import React from "react";
import styled from "styled-components";
import { CheckBoxType } from "../../types/types";

import { ReactComponent as CheckAct } from "../../assets/icons/check-active-icon.svg";
import { ReactComponent as Check } from "../../assets/icons/check-icon.svg";
import { ReactComponent as AllCheckAct } from "../../assets/icons/all-check-active-icon.svg";
import { ReactComponent as AllCheck } from "../../assets/icons/all-check-icon.svg";

const CheckBox = ({
  checked,
  onChange,
  children,
  type,
  className,
}: CheckBoxType) => {
  const handleCheck = () => {
    if (type) {
      if (checked) return <AllCheckAct />;
      else return <AllCheck />;
    } else {
      if (checked) return <CheckAct />;
      else return <Check />;
    }
  };
  return (
    <CheckArea className={className}>
      <Label>
        {handleCheck()}
        <input type="checkbox" checked={checked} onChange={onChange} />
      </Label>
      <Content>{children}</Content>
    </CheckArea>
  );
};

export default CheckBox;

const Content = styled.div`
  width: 100%;
  cursor: pointer;
`;

const Label = styled.label`
  margin-right: 8px;
  width: 19px;
  height: 19px;

  & > svg {
    width: 19px;
    height: 19px;
  }

  & > input {
    display: none;
  }
`;

const CheckArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &.margin-19 {
    margin-top: 19px;
  }
`;
