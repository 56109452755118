import loadable from "@loadable/component";

// 404페이지
export const NotFound = loadable(() => import("./NotFound"));

// 로그인
export const Root = loadable(() => import("./Root"));
export const Download = loadable(() => import("./Download"));

// 회원가입
export const JoinStep1 = loadable(() => import("./user/JoinStep1"));
export const JoinStep2 = loadable(() => import("./user/JoinStep2"));

// SNS로그인
export const Kakao = loadable(() => import("./appLogin/Kakao"));
export const Apple = loadable(() => import("./appLogin/Apple"));

// 커픽테스트
export const CoffikTest = loadable(() => import("./user/CoffikTest"));
export const TestPage = loadable(() => import("./coffikTest/TestPage"));
export const TestResult = loadable(() => import("./coffikTest/TestResult"));

// 아이디 찾기
export const FindEmail = loadable(() => import("./user/FindEmail"));

// 비밀번호 찾기
export const FindPassword = loadable(() => import("./user/FindPassword"));
export const FindPassword2 = loadable(() => import("./user/FindPassword2"));
export const NewPassword = loadable(() => import("./user/NewPassword"));

//메인
export const Main = loadable(() => import("./Main"));
export const MainBest = loadable(() => import("./main/MainBest"));
export const MainNew = loadable(() => import("./main/MainNew"));

//커피니티
export const Coffinity = loadable(() => import("./coffinity/Coffinity"));
export const FeedDetail = loadable(() => import("./coffinity/FeedDetail"));
export const FeedRegister = loadable(() => import("./coffinity/FeedRegister"));
export const FeedEdit = loadable(() => import("./coffinity/FeedEdit"));
export const TalkDetail = loadable(() => import("./coffinity/TalkDetail"));
export const TalkRegister = loadable(() => import("./coffinity/TalkRegister"));
export const TalkEdit = loadable(() => import("./coffinity/TalkEdit"));

//주문
export const Order = loadable(() => import("./order/Order"));
export const Detail = loadable(() => import("./order/Detail"));
export const Vr = loadable(() => import("./order/Vr"));
export const OrderDetail = loadable(() => import("./order/OrderDetail"));
export const Coupon = loadable(() => import("./order/Coupon"));
export const OrderCart = loadable(() => import("./order/OrderCart"));
export const CafePayment = loadable(() => import("./order/Payment"));
export const CafePaymentResult = loadable(() => import("./order/Result"));

//마켓
export const Market = loadable(() => import("./market/Market"));
export const MarketList = loadable(() => import("./market/List"));
export const MarketDetail = loadable(() => import("./market/Detail"));
export const Shop = loadable(() => import("./market/Shop"));
export const SubscribePayment = loadable(
  () => import("./market/SubscribePayment")
);
export const SubscribeResult = loadable(
  () => import("./market/SubscribeResult")
);
export const NormalPayment = loadable(() => import("./market/NormalPayment"));
export const NormalResult = loadable(() => import("./market/NormalResult"));
export const SetPayment = loadable(() => import("./market/SetPayment"));

//검색
export const Search = loadable(() => import("./search/Search"));
export const SearchDetail = loadable(() => import("./search/SearchDetail"));

// 마이페이지
export const Mypage = loadable(() => import("./mypage/Mypage"));
export const Setting = loadable(() => import("./mypage/Setting"));
export const EditPassword = loadable(() => import("./mypage/EditPassword"));
export const UseBlock = loadable(() => import("./mypage/UseBlock"));
export const EditInfo = loadable(() => import("./mypage/EditInfo"));
export const EditPhone = loadable(() => import("./mypage/EditPhone"));
export const EditAddress = loadable(() => import("./mypage/EditAddress"));
export const MyCoupon = loadable(() => import("./mypage/Coupon"));
export const Point = loadable(() => import("./mypage/Point"));
export const Like = loadable(() => import("./mypage/Like"));
export const MyCafeOrder = loadable(() => import("./mypage/MyCafeOrder"));
export const MyCafeOrderDetail = loadable(
  () => import("./mypage/MyCafeOrderDetail")
);
export const MyMarketOrder = loadable(() => import("./mypage/MyMarketOrder"));
export const MyMarketOrderDetail = loadable(
  () => import("./mypage/MyMarketOrderDetail")
);
export const Notice = loadable(() => import("./mypage/Notice"));
export const NoticeDetail = loadable(() => import("./mypage/NoticeDetail"));
export const CofficQna = loadable(() => import("./mypage/CofficQna"));
export const QnaRegister = loadable(() => import("./mypage/QnaRegister"));
export const OrderCancel = loadable(() => import("./mypage/OrderCancel"));
export const OrderCafeReview = loadable(
  () => import("./mypage/OrderCafeReview")
);
export const OrderMarketReview = loadable(
  () => import("./mypage/OrderMarketReview")
);
export const OrderDelivery = loadable(() => import("./mypage/OrderDelivery"));
export const OrderCancelDetail = loadable(
  () => import("./mypage/OrderCancelDetail")
);
export const OrderMarketCancel = loadable(
  () => import("./mypage/OrderMarketCancel")
);
export const OrderMarketQna = loadable(() => import("./mypage/OrderMarketQna"));
export const Reviews = loadable(() => import("./mypage/Reviews"));
export const Comment = loadable(() => import("./mypage/Comment"));
export const CoffeeTalk = loadable(() => import("./mypage/CoffeeTalk"));
export const Subscribe = loadable(() => import("./mypage/Subscribe"));
export const SubscribeDetail = loadable(
  () => import("./mypage/SubscribeDetail")
);
export const EditSubscribeAddress = loadable(
  () => import("./mypage/EditSubscribeAddress")
);
export const SubscribeVerification = loadable(
  () => import("./mypage/SubscribeVerification")
);
export const SubscribeCard = loadable(() => import("./mypage/SubscribeCard"));
export const SubscribeOrder = loadable(() => import("./mypage/SubscribeOrder"));
export const SubscribeOrderDetail = loadable(
  () => import("./mypage/SubscribeOrderDetail")
);

//장바구니
export const Cart = loadable(() => import("./cart/Cart"));

//약관
export const Service = loadable(() => import("./term/Service"));
export const Private = loadable(() => import("./term/Private"));
