import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { categoryModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/category-arrow-icon.svg";

const CategoryModal = () => {
  const [modal, setModal] = useRecoilState(categoryModalState);

  const [main, setMain] = useState<number>(0);
  const [mainStr, setMainStr] = useState<string>("");
  const [openChk, setOpenChk] = useState<any>(true);

  const { data } = useSWR(
    modal && `/${modal.type}/category${modal.type === "market" ? "/main" : ""}`
  );
  const { data: detail } = useSWR(
    modal && `/${modal.type}/category/sub?main_category_id=${main}`
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (detail && !openChk) {
      if(detail.categories?.length <= 0 && main > 0 && mainStr !== ""){
          modal?.onSelect && modal?.onSelect(main, 0, `${mainStr}`);
          handleClose();
      }
    }
  }, [detail]);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      if (modal !== null) {
        setOpenChk(true);
        
        modal?.main > 0 && setMain(modal?.main);
        modal?.category !== "" && setMainStr(modal?.category?.split(" > ")[0]);

        setTimeout(() => {
          setOpenChk(false);
        }, 500);
      }

      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
      setMain(0);
      setMainStr("");
      setOpenChk(true);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
      setMain(0);
      setMainStr("");
      setOpenChk(true);
    }, 300);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          카테고리 선택
          <Close onClick={handleClose} />
        </PopupHeader>
        {main > 0 && mainStr !== "" && detail?.categories?.length > 0 && (
          <FirstCategory>
            <button
              type="button"
              onClick={() => {
                setMain(0);
                setMainStr("");
              }}
            >
              <Arrow />
              {mainStr}
            </button>
          </FirstCategory>
        )}
        <SecondCategory className={main > 0 && mainStr !== "" ? "detail" : ""}>
          <CategoryBox
            className={modal?.sub === 0 && detail?.categories?.length > 0 ? "active" : ""}
            onClick={() => {
              setMain(0);
              setMainStr("");
              handleClose();

              if (main > 0 && mainStr !== "") {
                modal?.onSelect && modal?.onSelect(main, 0, `${mainStr}`);
              } else {
                modal?.onSelect && modal?.onSelect(0, 0, ``);
              }
            }}
          >
            <CategoryImage
              src={require("../../assets/images/all-category.png")}
              alt=""
            />
            전체 선택
          </CategoryBox>
          {main > 0 && mainStr !== "" && detail?.categories?.length > 0
            ? detail &&
              detail?.categories?.map(
                (
                  item: {
                    category_image: string;
                    category_name: string;
                    id: number;
                  },
                  i: number
                ) => (
                  <CategoryBox
                    key={"second_" + main + "_category_" + i}
                    className={modal?.sub === item.id ? "active" : ""}
                    onClick={() => {
                      setMain(0);
                      setMainStr("");
                      handleClose();
                      modal?.onSelect &&
                        modal?.onSelect(
                          main,
                          item.id,
                          `${mainStr} > ${item.category_name}`
                        );
                    }}
                  >
                    <CategoryImage src={item.category_image} alt="" />
                    {item.category_name}
                  </CategoryBox>
                )
              )
            : data &&
              data?.categories?.map(
                (
                  item: {
                    category_image: string;
                    category_name: string;
                    id: number;
                  },
                  i: number
                ) => (
                  <CategoryBox
                    key={"category_" + i}
                    className={modal?.main === item.id ? "active" : ""}
                    onClick={() => {
                      setMain(item.id);
                      setMainStr(item.category_name);
                    }}
                  >
                    <CategoryImage src={item.category_image} alt="" />
                    {item.category_name}
                  </CategoryBox>
                )
              )}
        </SecondCategory>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CategoryModal;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 11px;
  line-height: 1.4;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  object-fit: cover;
`;

const CategoryBox = styled.div`
  width: calc(25% - 18px);
  margin-right: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray2};
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  &:nth-of-type(n + 5) {
    margin-top: 22px;
  }
`;

const SecondCategory = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  height: calc(100% - 120px);
  overflow: auto;
  padding: 10px 24px;

  &.detail {
    height: calc(100% - 120px);
  }
`;

const FirstCategory = styled.div`
  padding: 14px 20px;

  & > button {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-radius: 6px;
    background: ${(props) => props.theme.color.gray7};
    font-size: 12px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: -0.2px;

    & > svg {
      width: 5px;
      height: 9px;
      margin-right: 8px;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
