import React, { useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { alertModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupText, PopupTitle } from "../elements/Typography";

const AlertModal = () => {
  const [modal, setModal] = useRecoilState(alertModalState);

  useEffect(() => {
    try {
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <TextBox>
          <PopupTitle className="center">{modal.title}</PopupTitle>
          <PopupText
            className="center"
            dangerouslySetInnerHTML={{ __html: modal.text }}
          />
          {modal?.bottom && modal?.bottom}
        </TextBox>
        <button type="button" onClick={handleConfirm}>
          {modal?.confirmText || "확인"}
        </button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default AlertModal;

const TextBox = styled.div`
  padding: 24px 22px;

  & > p {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  max-width: calc(100% - 60px);
  width: 100%;
  background: #ffffff;
  border-radius: 12px;

  & > button {
    width: 100%;
    height: 52px;
    background: none;
    border-radius: 0;
    border: 0;
    border-top: 1px solid ${(props) => props.theme.color.line};
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.028px;
    color: ${(props) => props.theme.color.gray1};
  }
`;
