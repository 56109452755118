import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { reportModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { reportOptions } from "../../utils/CommonFunction";

import { ReactComponent as Check } from "../../assets/icons/select-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import Button from "../elements/Button";

const ReportModal = () => {
  const [modal, setModal] = useRecoilState(reportModalState);

  const [select, setSelect] = useState<string[]>([]);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleSelect = (value: string) => {
    let arr = [...select];

    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }

    setSelect(arr);
  };

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };
  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          신고 유형 선택
          <Close onClick={handleClose} />
        </PopupTitle>
        <PopupText>
          {reportOptions?.map((option) => (
            <List
              onClick={() => handleSelect(option)}
              className={select.includes(option) ? "select" : ""}
            >
              {option}
              {select.includes(option) && <Check />}
            </List>
          ))}
        </PopupText>
        <Button
          disabled={select.length <= 0}
          onClick={() => {
            modal?.onClick && modal?.onClick(select);
            handleClose();
            setSelect([]);
          }}
        >
          신고
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ReportModal;

const List = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 0 20px 18px;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;

  & > button {
    margin: 0 20px 20px;
    width: calc(100% - 40px);
  }
`;
