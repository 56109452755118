const color = {
  white: "#FFFFFF",
  black: "#000000",

  main: "#04EE9E",
  error: "#FF2F2F",
  red: "#FF6682",
  redSub: "#F394A6",
  cart: "#FB5656",
  line: "#F1F2F3",

  gray1: "#151420",
  gray2: "#3C3B4B",
  gray3: "#5D5D6D",
  gray4: "#ADADBC",
  gray5: "#CDCDD6",
  gray6: "#E7E7EE",
  gray7: "#F7F7FA",
  gray8: "#F3F3F3",
};

export type ColorType = typeof color;

export default { color };
