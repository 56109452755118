import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { cafeModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupHeader } from "../elements/Typography";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Search } from "../../assets/icons/search-gray-icon.svg";
import { ReactComponent as Replace } from "../../assets/icons/input-replace-icon.svg";
import { ReactComponent as EmptyIcon } from "../../assets/icons/location-empty-icon.svg";
import CafeSearchList from "../list/CafeSearchList";
import { CafeType } from "../../types/api";

const CafeModal = () => {
  const [modal, setModal] = useRecoilState(cafeModalState);

  const [search, setSearch] = useState<string>("");
  const [place, setPlace] = useState<boolean>(false);

  const { data: list } = useSWR(modal && `/cafe?search=${search}`);
  const { data: list2 } = useSWR(
    modal && `/cafe/kakao/search?keyword=${search}`
  );

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
      setSearch("");
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
      setSearch("");
    }, 300);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupHeader>
          카페 검색
          <Close onClick={handleClose} />
        </PopupHeader>
        <InputArea>
          <SearchArea>
            <Search />
            <input
              type="text"
              placeholder="카페명 장소로 검색"
              value={search}
              onChange={(e) => {
                setPlace(false);
                setSearch(e.target.value);
              }}
            />
            {search !== "" && <Replace onClick={() => setSearch("")} />}
          </SearchArea>
        </InputArea>
        {search !== "" &&
          (list?.cafes?.data?.length > 0 ||
          list2?.results?.documents?.length > 0 ? (
            <PopupContent>
              {list?.cafes?.data?.length > 0 && (
                <>
                  <Title>카페로 찾기</Title>
                  <div>
                    {list?.cafes?.data?.map((item: CafeType, i: number) => (
                      <CafeSearchList
                        key={"search_cafe_" + i}
                        item={item}
                        onClick={() => {
                          modal?.onClick && modal?.onClick(item);
                          handleClose();
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
              {list2?.results?.documents?.length > 0 && (
                <>
                  <Title>주소로 찾기</Title>
                  <div>
                    {list2?.results?.documents?.map(
                      (item: CafeType, i: number) => (
                        <CafeSearchList
                          key={"search_cafe_" + i}
                          type="address"
                          item={item}
                          onClick={() => {
                            modal?.onClick &&
                              modal?.onClick({
                                //@ts-ignore
                                place_name: item?.place_name,
                                //@ts-ignore
                                address_name: item?.address_name,
                              });
                            handleClose();
                          }}
                        />
                      )
                    )}
                  </div>
                </>
              )}
            </PopupContent>
          ) : (
            <Empty>
              <EmptyIcon />
              <p>결과에 맞는 카페를 찾지 못했어요.</p>
              <button
                type="button"
                onClick={() => {
                  modal?.onClick && modal?.onClick(search);
                  handleClose();
                }}
              >
                <span>{search}</span>를 장소로 지정
              </button>
            </Empty>
          ))}
      </Wrapper>
    </ModalWrapper>
  );
};

export default CafeModal;

const Title = styled.p`
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};

  &:nth-of-type(n + 2) {
    margin-top: 18px;
  }
`;

const Empty = styled.div`
  padding: 109px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    margin: 12px 0 22px;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    color: ${(props) => props.theme.color.gray4};
  }

  & > button {
    padding: 8px 10px;
    border-radius: 5px;
    background: ${(props) => props.theme.color.gray7};
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray3};

    & > span {
      font-weight: 600;
      color: ${(props) => props.theme.color.red};
      margin-right: 3px;
    }
  }
`;

const PopupContent = styled.div`
  width: 100%;
  height: calc(100% - 58px - 76px);
  overflow: auto;
`;

const SearchArea = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 12px;
  border-radius: 10px;
  background: ${(props) => props.theme.color.gray7};

  & > svg {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }

  & > input {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray1};
    margin: 0 9px;
    background: none;

    &::placeholder {
      color: ${(props) => props.theme.color.gray4};
    }
  }
`;

const InputArea = styled.div`
  padding: 14px 20px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
