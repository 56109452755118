import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { cafeListModalState, selectModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import CafeList from "../list/CafeList";
import { mapOrderOptions } from "../../utils/CommonFunction";

import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import { ReactComponent as Order } from "../../assets/icons/list-order-icon.svg";
import Empty from "../elements/Empty";

const CafeListModal = () => {
  const [modal, setModal] = useRecoilState(cafeListModalState);
  const setSelectModal = useSetRecoilState(selectModalState);

  const wrapperRef = useRef(null);
  const content = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null && content.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";

        if (window.localStorage.getItem("near")) {
          const el: any = content.current;
          el.scrollTo({ top: Number(window.localStorage.getItem("near")) });
        } else {
          window.localStorage.setItem("near", "0");
        }
      });
    }
  }, [wrapperRef, content, modal]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      window.localStorage.removeItem("near");
      setModal(null);
    }, 200);
  };

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.bottom = "-100%";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const infiniteScroll = (e: React.UIEvent<HTMLElement>) => {
    //@ts-ignore
    let scrollTop = e.target.scrollTop;
    window.localStorage.setItem("near", scrollTop);
  };

  useEffect(() => {
    if (content.current !== null) {
      //@ts-ignore
      content.current.addEventListener("scroll", infiniteScroll, true);

      return () => {
        try {
          //@ts-ignore
          content.current.removeEventListener("scroll", infiniteScroll, true);
        } catch (error) {}
      };
    }
  }, [content, modal]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          주변 카페 리스트
          <Close onClick={handleClose} />
        </PopupTitle>
        <OrderBox>
          <button
            type="button"
            onClick={() =>
              setSelectModal({
                title: "정렬 순",
                options: mapOrderOptions,
                value: modal?.order?.text,
                onSelect: (value) => {
                  modal?.setOrder && modal?.setOrder(value);
                },
              })
            }
          >
            {modal?.order?.text?.replaceAll(" ", "")}
            <Order />
          </button>
        </OrderBox>
        <PopupText ref={content}>
          {modal?.item?.length > 0 ? (
            modal?.item?.map((item, i) => (
              <CafeList
                key={i}
                distance={item?.user_distance}
                end_time={item?.end_time}
                id={item?.id}
                is_my_like={item?.is_my_like}
                is_close={item?.is_open === 1 ? "N" : "Y"}
                image_url={item?.main_image_url}
                name={item?.name}
                review_count={item?.review_count}
                review_score={item?.review_score}
                start_time={item?.start_time}
                tag={item?.tag}
                holidays={item?.holidays}
                order_type={item?.order_type}
                is_open={item?.is_open}
                address={item?.address}
                address_detail={item?.address_detail}
              />
            ))
          ) : (
            <Empty type="huge" text="조건에 맞는<br/>매장을 찾을 수 없어요." />
          )}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default CafeListModal;

const PopupText = styled.div`
  width: 100%;
  height: calc(100% - 43px - 58px);
  overflow: auto;
`;

const OrderBox = styled.div`
  padding: 14px 20px;

  & > button {
    margin-left: auto;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.color.gray2};

    & > svg {
      margin-left: 5px;
    }
  }
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;

  & > button {
    margin: 0 20px 20px;
    width: calc(100% - 40px);
  }
`;
