import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChildrenType } from "../types/types";


const Preview = ({ children }: ChildrenType) => {
  let pcDevice = "win16|win32|win64|mac|macintel";

  const [os, setOs] = useState("pc");

  useEffect(() => {
    const varUA = navigator.userAgent.toLowerCase();
    const varPl = navigator.platform.toLowerCase();

    if (
      varUA.indexOf("android") > -1 ||
      varUA.match("coffik/Android") != null
    ) {
      setOs("android");
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1 ||
      varUA.match("coffik/iOS") != null
    ) {
      setOs("ios");
    } else {
      setOs("pc");
    }
  }, []);

  return (
    <Wrapper>
      <Content>
        <PreviewArea
          className={
            (pcDevice.indexOf(navigator.platform.toLowerCase()) >= 0
              ? ""
              : "mobile") +
            " " +
            os
          }
        >
          {children}
        </PreviewArea>
      </Content>
    </Wrapper>
  );
};

export default Preview;

const PreviewArea = styled.div`
  width: 420px;
  min-width: 420px;
  height: 100%;
  border: 0;
  background: #fff;
  position: relative;
  overflow: hidden;
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.1));

  margin: auto;

  &.mobile {
    width: 100%;
    min-width: 0px;
    filter: none;
    background: none;
  }

  @media (max-width: 960px) {
    width: 100%;
    min-width: 0px;
    filter: none;
    background: none;
  }
`;

const ButtonBox = styled.div`
  & > button {
    width: 100%;

    &:nth-of-type(n + 2) {
      margin-top: 12px;
    }
  }
`;

const PreviewText = styled.p`
  margin: 25px 0 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.4px;
  color: ${(props) => props.theme.color.gray1};
`;

const PreviewBox = styled.div`
  max-width: 420px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0 77px;

  &.mobile {
    width: 100%;
    min-width: 0px;
  }

  & > svg {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

const Content = styled.div`
  max-width: 840px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;
