import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { pictureDetailModalState } from "../../utils/atom";
import ZoomImage from "../elements/ZoomImage";
import ModalWrapper from "./ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/thick-close-white-icon.svg";

// 사용방법
// setPictureDetailModalState({
//   start: 시작 index,
//   picture: 이미지 배열,
//   onClick: (index : 닫았을때 마지막으로 보고있던 이미지 인덱스) => {
//     이미지 index로 이동하는 소스코드 작성
//   },
// })

const PictureDetailModal = ({}) => {
  const [modal, setModal] = useRecoilState(pictureDetailModalState);

  const [index, setIndex] = useState<number>(0);

  const swiper = useRef(null);

  useEffect(() => {
    if (swiper.current !== null && modal && modal?.start) {
      //@ts-ignore
      swiper.current.swiper.slideTo(modal?.start, 0);
      setIndex(modal?.start);
    }
  }, [modal, swiper]);

  useEffect(() => {
    try {
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleConfirm = () => {
    setModal(null);
    setIndex(0);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="black" onClose={handleConfirm}>
      <Hearder>
        <span>{index + 1}</span>/{modal?.picture?.length}
        <CloseButton
          onClick={() => {
            modal?.onClick && modal?.onClick(index);
            handleConfirm();
          }}
        >
          <Close />
        </CloseButton>
      </Hearder>
      <Content>
        <Detail>
          <DetailSwiper
            ref={swiper}
            onSlideChange={(swiper: any) => setIndex(swiper.activeIndex)}
          >
            {modal?.picture?.map((image, i) => (
              <SwiperSlide key={"detail-image-" + i}>
                <ZoomImage image={image?.image_url} />
              </SwiperSlide>
            ))}
          </DetailSwiper>
        </Detail>
      </Content>
    </ModalWrapper>
  );
};

export default PictureDetailModal;

const DetailSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
`;

const Detail = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 18px;
  right: 20px;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const Hearder = styled.div`
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.6;
  color: ${(props) => props.theme.color.white};
  z-index: 10;

  & > span {
    color: ${(props) => props.theme.color.main};
  }
`;
