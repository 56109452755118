import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { selectModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { OptionType } from "../../types/types";

import { ReactComponent as Check } from "../../assets/icons/select-icon.svg";

const SelectModal = () => {
  const [modal, setModal] = useRecoilState(selectModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleSelect = (value: OptionType) => {
    modal?.onSelect && modal?.onSelect(value);
    handleConfirm();
  };

  const handleConfirm = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };
  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>{modal?.title}</PopupTitle>
        <PopupText>
          {modal.options?.map((option) => (
            <List
              onClick={() => handleSelect(option)}
              className={
                (option.text ? option.text : option.value) === modal.value
                  ? "select"
                  : ""
              }
            >
              {option.text ? option.text : option.value}
              {(option.text ? option.text : option.value) === modal.value && (
                <Check />
              )}
            </List>
          ))}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SelectModal;

const List = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 3px 20px 20px;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
