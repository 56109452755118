import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { PopupTitle, PopupText } from "../elements/Typography";
import auth from "../../utils/auth";

const varUA = navigator.userAgent;

const TwoButtonModal = () => {
  const [modal, setModal] = useRecoilState(twoButtonModalState);

  useEffect(() => {
    try {
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleCancle = () => {
    if (modal?.title === "로그인 필요") modal?.onClose && modal.onClose();
    if (modal?.confirmText === "계속 진행할게요")
      window.history.pushState(null, "", window.location.href);
    setModal(null);
  };

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    if (modal?.title === "로그인 필요") window.localStorage.clear();
    if (modal?.title === "위치 서비스 허용") handleLocation();

    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };

  const handleCancelConfirm = () => {
    modal?.onCancelConfirm && modal.onCancelConfirm();
    setModal(null);
  };

  const location = useRef(null);

  const handleLocation = () => {
    try {
      if (varUA.match("coffik/Android") != null) {
        window.coffik.postMessage(JSON.stringify({ type: "openSetting" }));
        window.localStorage.setItem("androidOpen", "true");
      } else if (varUA.match("coffik/iOS") != null) {
        window.webkit.messageHandlers.getlatlngHandler.postMessage({});
      } else if (varUA.match("coffik15/Android") !== null) {
        window.coffik.openSetting();
      } else {
        navigator.geolocation.watchPosition(success, error);
      }
    } catch (error) {}
  };

  const success = (pos: any) => {
    const latitude = pos.coords.latitude;
    const longitude = pos.coords.longitude;

    auth.setLat(latitude);
    auth.setLng(longitude);
  };

  const error = (err: any) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleCancle}>
      <Wrapper>
        <TextBox>
          <PopupTitle className="center">{modal.title}</PopupTitle>
          <PopupText
            className="center"
            dangerouslySetInnerHTML={{ __html: modal.text }}
          />
        </TextBox>
        <FlexBox>
          <button
            type="button"
            onClick={modal?.cancleText ? handleCancelConfirm : handleClose}
          >
            {modal?.cancleText || "아니오"}
          </button>
          <button type="button" className="line" onClick={handleConfirm}>
            <b>{modal?.confirmText || "예"}</b>
          </button>
        </FlexBox>
      </Wrapper>
    </ModalWrapper>
  );
};

export default TwoButtonModal;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: solid 1px ${(props) => props.theme.color.line};

  & > button {
    width: 100%;
    height: 52px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.028px;
    color: ${(props) => props.theme.color.gray1};

    &:nth-of-type(n + 2) {
      border-left: solid 1px ${(props) => props.theme.color.line};
    }
  }
`;

const TextBox = styled.div`
  padding: 24px 22px;

  & > p {
    margin-top: 16px;
  }
`;

const Wrapper = styled.div`
  max-width: calc(100% - 60px);
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
`;
