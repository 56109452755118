import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { subscribeDetailModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { Dot, DotDateTime } from "../../utils/CommonFunction";
import { ReactComponent as Close } from "../../assets/icons/popup-close-icon.svg";
import useSWR from "swr";

const SubscribeDetailModal = () => {
  const [modal, setModal] = useRecoilState(subscribeDetailModalState);
  const { data } = useSWR(
    modal?.id ? `/mypage/subscribe/purchase?id=${modal?.id}` : ""
  );
  const listData = data?.subscribe_purchase;

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <Title>
          <p>결제내역</p>
          <Close onClick={handleClose} />
        </Title>
        <Info>
          <div>
            <p>{modal?.order}</p>
            <p className="date">첫 결제일 {DotDateTime(modal?.date)}</p>
          </div>
        </Info>
        <Content>
          <Menu>
            <p>결제 일시</p>
            <p>결제 카드</p>
            <p>결제 금액</p>
          </Menu>
          {listData?.map((item: any, i: number) => (
            <Menu key={`subscribe_detail_modal_list_${i}`} className="list">
              <p>{DotDateTime(item.purchase_date)}</p>
              <p>{item.card_info}</p>
              <p>{Dot(item.total_price)}</p>
            </Menu>
          ))}
        </Content>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SubscribeDetailModal;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: calc(100% - 60px);
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
const Title = styled.div`
  padding: 26px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.2%;
    color: #151420;
  }
`;
const Info = styled.div`
  padding: 5px 20px;
  & div {
    padding: 10px 11px;
    border-radius: 6px;
    background: #f7f7fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > p {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #151420;
      &.date {
        font-weight: 400;
        font-size: 13px;
        line-height: 19.5px;
        color: #5d5d6d;
      }
    }
  }
`;
const Content = styled.div`
  padding: 20px;
  width: 100%;
  max-height: calc(100% - 120px);
  overflow: auto;
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #151420;
  &.list {
    padding: 14px 0;
    border-bottom: 1px solid #f1f2f3;
    & p {
      font-weight: 400;
      font-size: 13px;
    }
  }
  & p {
    min-width: 100px;
    font-weight: 600;
    font-size: 11px;
    line-height: 1;
    color: #151420;
    text-align: center;
    margin-right: 10px;
  }
  & p:last-of-type {
    margin-right: 0;
  }
`;
