import React from "react";
import styled from "styled-components";
import { Dot } from "../../utils/CommonFunction";
import { CafeType } from "../../types/api";

import { ReactComponent as Star } from "../../assets/icons/list-star-icon.svg";

const CafeSearchList = ({
  item,
  onClick,
  type = "cafe",
}: {
  item: CafeType;
  onClick: () => void;
  type?: "cafe" | "address";
}) => {
  return (
    <Wrapper onClick={onClick}>
      <div>
        {type === "cafe" && item.main_image_url && (
          <Image src={item.main_image_url} alt="" />
        )}
        <InfoArea>
          <Name>{type === "cafe" ? item.name : item?.place_name}</Name>
          <Address>
            {type === "cafe"
              ? item.address + " " + item.address_detail
              : item?.address_name}
          </Address>
          {type === "cafe" && (
            <Score>
              <StarIcon />
              {Number(item.review_score).toFixed(1)}({Dot(item.review_count)})
            </Score>
          )}
        </InfoArea>
      </div>
    </Wrapper>
  );
};

export default CafeSearchList;

const StarIcon = styled(Star)`
  margin-right: 4px;
  width: 10px;
  height: 10px;
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray3};
  margin-top: 10px;
`;

const Address = styled.div`
  margin: 8px 0 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray3};
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};
`;

const InfoArea = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 62px;
  min-width: 62px;
  height: 62px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  object-fit: cover;
  margin-right: 12px;
`;

const Wrapper = styled.div`
  padding: 0 20px;
  cursor: pointer;

  & > div {
    padding: 12px 0;
    display: flex;
    align-items: center;
  }

  &:nth-of-type(n + 2) > div {
    border-top: 1px solid ${(props) => props.theme.color.line};
  }
`;
