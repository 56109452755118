import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { ProfileMenuState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";

const ProfileMenuModal = () => {
  const [modal, setModal] = useRecoilState(ProfileMenuState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  useEffect(() => {
    try {
      //@ts-ignore
      wrapperRef.current.style.top = "-100px";
      setModal(null);
    } catch (error) {}
  }, [window.location.href]);

  const handleClose = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>프로필 설정</PopupTitle>
        <PopupText>
          <List
            onClick={() => {
              modal?.onChange();
              handleClose();
            }}
          >
            변경
          </List>
          <List
            onClick={() => {
              modal?.onRemove();
              handleClose();
            }}
          >
            삭제
          </List>
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default ProfileMenuModal;

const List = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.color.gray1};
  letter-spacing: -0.028px;

  &.select {
    font-weight: 700;
  }

  & > svg {
    width: 14px;
    height: 10px;
  }
  & span {
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    color: #ff6682;
    text-align: right;
    margin-right: 10px;
  }
`;

const PopupText = styled.div`
  width: 100%;
  padding: 3px 20px 20px;
`;

const PopupTitle = styled.div`
  padding: 26px 20px 16px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.032px;
  color: ${(props) => props.theme.color.gray1};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 22px 22px 0 0;
  bottom: -100%;
  transition: all 0.3s;
`;
