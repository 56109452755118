import React from "react";
import styled, { css } from "styled-components";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | React.ReactNode[];
  size?: "sm" | "md" | "empty";
  disabled?: boolean;
  className?: string;
};

const Button = ({
  children,
  size = "md",
  disabled,
  className,
  ...props
}: ButtonProps) => {
  return (
    <Wrapper className={className} size={size} disabled={disabled} {...props}>
      {children}
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.button<{
  size: "sm" | "md" | "empty";
  disabled?: boolean;
}>`
  //크기 중간
  width: 100%;
  height: 48px;

  //기본
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  background: ${(props) => props.theme.color.gray1};
  border: solid 1px ${(props) => props.theme.color.gray1};

  &:hover,
  &:active {
    background: ${(props) => props.theme.color.black};
    border-color: ${(props) => props.theme.color.black};
  }

  &:disabled {
    color: ${(props) => props.theme.color.gray4};
    background: ${(props) => props.theme.color.gray6};
    border-color: ${(props) => props.theme.color.gray6};
  }

  //원형
  &.circle {
    border-radius: 50px;
  }

  //선
  &.line {
    font-weight: 500;
    color: ${(props) => props.theme.color.gray1};
    background: ${(props) => props.theme.color.white};
    border-color: ${(props) => props.theme.color.gray1};

    &:hover,
    &:active {
      color: ${(props) => props.theme.color.gray1};
      background: ${(props) => props.theme.color.gray7};
      border-color: ${(props) => props.theme.color.gray1};
    }

    &:disabled {
      color: ${(props) => props.theme.color.gray4};
      background: ${(props) => props.theme.color.white};
      border-color: ${(props) => props.theme.color.gray5};
    }
  }

  //네모
  &.square {
    border-radius: 0px;
  }

  ${(props) =>
    props.size === "sm" &&
    css`
      width: 70px;
      height: 33px;
      border-radius: 6px;
      font-size: 13px;
      letter-spacing: -0.026px;
    `}

  ${(props) =>
    props.size === "empty" &&
    css`
      width: 120px;
      height: 33px;
      border-radius: 6px;
      font-size: 13px;
      letter-spacing: -0.026px;

      &.line {
        font-weight: 400;
        border-color: ${(props) => props.theme.color.gray6};

        &:hover,
        &:active {
          border-color: ${(props) => props.theme.color.gray6};
        }
      }
    `}
`;
